<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto">
          <h1>Managed Properties</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.propertyDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Property</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-sbpm-managedproperties-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Properties</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="properties"
        class="mt-4"
        no-data-text="No Properties found"
      >
        <template v-slot:item.communal_repair="{ item }">
          <v-icon v-if="item.repairs.length > 0" color="green"
            >mdi-check-circle
          </v-icon>
        </template>

        <template v-slot:item.property_name="{ item }">
          {{ item.property_name }}, {{ item.postcode }}
        </template>

        <template v-slot:item.checkout_due_date="{ item }">
          {{
            item.last_checkout ? formatDate(item.last_checkout.due_date) : ""
          }}
        </template>

        <template v-slot:item.move_out_due_date="{ item }">
          {{
            item.last_move_out ? formatDate(item.last_move_out.due_date) : ""
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                class="mr-2"
                :to="{
                  name: 'module-sbpm-managedproperties-individual',
                  params: { propertyId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog v-model="deleteProperty.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property</v-card-title>
        <v-card-text
          >Are you sure you want to archive this property form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProperty.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PropertyDialog ref="propertyDialog" />
  </div>
</template>

<script>
import PropertyDialog from "./components/PropertyDialog.vue";

export default {
  components: {
    PropertyDialog,
  },

  data() {
    return {
      deleteProperty: {
        dialog: false,
        property: {},
        loading: false,
      },
      tableHeaders: [
        { text: "Address", value: "property_name" },
        { text: "Communal Repair", value: "communal_repair" },
        { text: "Check Out", value: "checkout_due_date" },
        { text: "Move Out", value: "move_out_due_date" },
        { text: "Actions", value: "actions", sortable: false, align: "end" },
      ],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["sbpm/propertiesStore/all"];

      properties = properties.filter((c) => c.status == "Managing");

      if (this.searchTerm !== "") {
        properties = properties.filter((p) => {
          const short = p.property_name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return short.includes(searchTerm);
        });
      }

      return properties;
    },
  },

  methods: {
    openDelete(property) {
      this.deleteProperty.property = property;
      this.deleteProperty.dialog = true;
    },

    resetDelete() {
      this.deleteProperty.dialog = false;
      this.deleteProperty.property = {};
      this.deleteProperty.loading = false;
    },

    saveDelete() {
      this.deleteProperty.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.deleteProperty.property.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProperty.loading = false;
        });
    },
  },
};
</script>
